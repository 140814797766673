@use '../vendor/modern-normalize.css';
// @use '../fonts/fonts';
@use './util';

:root {
  --header-height-fullscreen: 64px;
  --header-height-width-under-431: 57px;

  font-family: var(--font-aktiv-grotesk), 'Aktiv Grotesk', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  font-family: var(--font-aktiv-grotesk), 'Aktiv Grotesk', Arial, sans-serif;
}

#app {
  width: 100%;
}

.page {
  width: 100%;
  margin: 0;
  height: var(--header-height-fullscreen);

  @media screen and (width <= 431px) {
    height: var(--header-height-width-under-431);
  }
}
