$desktop: 991px;

@media (width > $desktop) {
  .desktop-hidden {
    display: none !important;
  }
}

@media (width <= $desktop) {
  .mobile-hidden {
    display: none !important;
  }
}
