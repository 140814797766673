.dropup {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 40px;
  right: 0;
  left: 0;
  z-index: 50;
  opacity: 0;

  // transform: translate(-50%, 100%);
  transition:
    opacity 0.2s ease-out,
    transform 0.2s ease-out;
}

.dropup_opened {
  opacity: 1;
}

.dropup__toggle-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 30px rgb(121 121 121 / 10%);
  width: 185px;
  cursor: pointer;
  outline: none;
  background-color: rgb(255 255 255 / 90%);
  border: 0;
  border-radius: 2rem;
  padding: 0.625rem;
  transition: background-color 0.2ms ease-out;

  @media screen and (width <= 431px) {
    width: 160px;
  }
}

.dropup__toggle-btn:hover,
.dropup__toggle-btn_full-opacity {
  background-color: rgb(255 255 255 / 100%);
}

.dropup__toggle-btn-title {
  color: black;
  font-size: 1.05rem;
  line-height: 1.1;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
}

.dropup__toggle-btn-arrow {
  color: black;
  margin-bottom: 3px;
  vertical-align: middle;
  content: '';
  border-bottom: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  transition: transform 100ms;
}

.dropup__toggle-btn_opened .dropup__toggle-btn-arrow {
  transform: rotate(180deg);
}

.dropup__menu {
  width: 185px;
  overflow: visible;
  position: absolute;
  z-index: 100;
  top: auto;
  bottom: 100%;
  background-color: #fff;
  border: 0;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  padding: 1rem 0;
  text-align: center;
  box-shadow: 0 0 30px rgb(121 121 121 / 10%);

  @media screen and (width <= 431px) {
    width: 160px;
  }
}

.dropup__menu-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.dropup__menu-link {
  display: block;
  text-decoration: none;
  margin: 0;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  cursor: pointer;
  color: black;
  font-size: 0.9rem;
  line-height: 1.4;
  font-weight: 400;
  transition: all 0.2s ease-out;

  @media screen and (width <= 431px) {
    font-size: 0.825rem;
    line-height: 1.25rem;
  }
}

.dropup__menu-link:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f7f7f9;
}

.dropup__menu-link.active {
  color: #fff;
  text-decoration: none;
  background-color: #333;
}
