.header {
  height: 65px;
  // padding: 14px 34px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  background-color: rgb(255 255 255 / 90%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-bottom-left-radius: 15px;
  //border-bottom-right-radius: 15px;

  @media screen and (width <= 431px) {
    height: auto;
  }
}

.header__item {
  height: 100%;
  display: flex;
  position: relative;
}

.header__link {
  padding: 16px 34px;
  margin: 0;
  height: 100%;
  text-align: center;
  align-content: center;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  transition: transform 0.2s ease-out;

  &:hover {
    transform: scale(1.1);
  }

  &.active {
    color: #737373;
  }

  @media screen and (width <= 769px) {
    padding-inline: 24px;
  }

  @media screen and (width >= 1900px) {
    padding-inline: 54px;
  }
}

.header__dropdown {
  background-color: rgb(255 255 255 / 90%);
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 150;
  top: calc(var(--header-height-fullscreen) + 1px);
  //left: 30px;
  //background-color: transparent;
  text-align: start;
  width: 100vw;
  padding: 0 0 20px;
  transition: opacity 0.3s ease-out;

  .header__item--services:hover & {
    visibility: visible;
    opacity: 1;
  }

  @media screen and (width <= 431px) {
    top: var(--header-height-width-under-431);
  }
}

.header__dropdown-link {
  margin-left: 34px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: black;

  @media screen and (width >= 1900px) {
    margin-left: 54px;
  }

  &:hover {
    text-decoration-line: underline;
    text-underline-offset: 7px;
  }

  &.active {
    text-decoration-line: underline;
    text-underline-offset: 7px;
    color: #737373;
  }
}

.header__logo {
  height: 28px;
  aspect-ratio: 3.54642857;
}

@media screen and (width <= 991px) {
  .header__logo {
    height: 25px;
  }
}

.header__link_logo {
  line-height: 0;
}

.header__link_logo:hover {
  transform: none;
}

.header__menu-list {
  margin: 6px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 18px;
  list-style: none;
}

//drawer

.drawer {
  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f0f2f3;
  color: black;
  z-index: 100;

  &.drawer_open {
    display: flex;
  }
}

.drawer__btn {
  display: flex;
  align-items: center;
  padding: 18px 34px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;

  @media screen and (width <= 769px) {
    padding: 16px 24px;
  }
}

.drawer__cross {
  width: 25px;
  height: 25px;
  transform: rotate(45deg);
  transition: transform 0.2s ease-out;
}

.drawer__cross_close {
  transform: rotate(90deg);
}

.drawer__menu,
.drawer__submenu {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
}

.drawer__menu {
  gap: 40px;
}

.drawer__submenu-title {
  margin: 0 0 10px;
}

.header__submenu-link {
  transition: transform 0.2s ease-out;
  text-decoration: none;
  color: black;
  font-size: 18px;
  margin: 0 0 8px;

  &:hover {
    transform: scale(1.1);
  }

  &.active {
    color: #737373;
    border-bottom: 1px solid;
  }
}

.drawer__link,
.drawer__submenu-title {
  text-decoration: none;
  color: black;
  font-size: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;
}

.drawer__link {
  transition: transform 0.2s ease-out;

  &:hover {
    transform: scale(1.1);
  }

  &.active {
    color: #737373;
  }
}
